.game-banner-wraper{
  /* height: 800vh; */
  /* overflow: scroll; */
   transition: all ease-in-out .5s;-webkit-transition: all ease-in-out .5s; -moz-transition: all ease-in-out .5s; -ms-transition: all ease-in-out .5s; -o-transition: all ease-in-out .5s;
  background-image: url('./assets/images/bg.jpg');
  background-size: 100%;  background-attachment: fixed;  position: relative;background-position: 0 -100px;  background-repeat: no-repeat; }
.game-banner-wraper::after{
    background-image: url('./assets/images/base-layer.png');
    position: fixed;
    bottom: -30px;
    z-index: 10;
    content: "";
    height: 100%;
    width: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(30vh - 100px);
}
.bridge-background {
  position: fixed;
  /* height: 100vh; */
  left: 0;
  top: 0;
  left: 0;
}
.bridge-background img{width: 100vw;height: 100vh;}
.game-banner-wraper .sun-image {
  position: fixed;
  top: -20%;
  left: 50%;
  transform: translate(-50%);
  /* z-index: 2; */
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
/* .sun-image img{width: 500px;height: 500px;} */
/* .cloud-image {width: 104px;position: absolute;top: 0;z-index: 3;} */
.stairs-image {position: fixed;top: 38%;left: 50%;transform: translate(-50%, -50%) rotateX(40deg);width: 100%;max-width: 20%;opacity: 0;transition: all ease-in-out 0.3s;}
.related-nft .nft-name{white-space: nowrap;background: #c7d0d8;border: 2px solid #77838d; padding: 5px 10px;border-radius: 20px;font-size: 18px;line-height: 21px;font-weight: 700;margin-bottom: 0;}
.related-nft .nft-name-img:not(:last-child){margin-bottom: 5px;}
.more-button{
  position: absolute;
  bottom: -30%;
  font-weight: 700;
  background: transparent;
  border: 2px solid #000;
  border-radius: 5px;
  padding: 2px 8px;
}
.user-nft-img{
  width: 30px;
  height: 30px;
  /* border-radius: 50%; */
}
.user-nft-img img{
  border-radius: 50%;
}
.nft-fram{
  max-width: 200px;
  transition: all ease-in-out 0.3s;
}
.nft-fram-wrapper{
  position: absolute;
  bottom: 80%;
}
.right-nft-fram-wrapper{
  right: 6%;
}
.left-nft-fram-wrapper{
  left: 50%;
  transform: translateX(-50%);
}
.tile{max-width: 500px;transition: all ease-in-out 0.3s;}
/* .tile-row{position: fixed;width: 70%;left: 50%;} */
.tile-row{position: fixed;width: 100%;left: 50%;}
.tile-row .nft-fram-wrapper{opacity: 0;transition: all ease-in-out 0.3s;}
.tile-row1-image .nft-fram-wrapper{opacity: 1;}

.tile-row1-image{top: 83%;transform: translate(-50%, -50%) scale(1);;}
.tile-row2-image{top: 75%;transform: translate(-50%, -50%) scale(0.8);}
.tile-row3-image{top: 60%;transform: translate(-50%, -50%) scale(0.6);}
.tile-row4-image{top: 55%;transform: translate(-50%, -50%) scale(0.45);}
.tile-row5-image{top: 46%;transform: translate(-50%, -50%) scale(0.33);}
.tile-row6-image{top: 38%;transform: translate(-50%, -50%) scale(0.28);opacity: 0;}
.tile-row7-image{top: 38%;transform: translate(-50%, -50%) scale(0.28);opacity: 0;}
.tile-row8-image{top: 38%;transform: translate(-50%, -50%) scale(0.28);opacity: 0;}
.tile-row9-image{top: 38%;transform: translate(-50%, -50%) scale(0.28);opacity: 0;}
.tile-row10-image{top: 38%;transform: translate(-50%, -50%) scale(0.28);opacity: 0;}
.tile-row11-image{top: 38%;transform: translate(-50%, -50%) scale(0.28);opacity: 0;}
.tile-row12-image{top: 38%;transform: translate(-50%, -50%) scale(0.28);opacity: 0;}
.tile-row13-image{top: 38%;transform: translate(-50%, -50%) scale(0.28);opacity: 0;}
.tile-row2-image .nft-fram-wrapper{
  bottom: 90%;
}
.tile-row3-image .nft-fram-wrapper{
  bottom: 90%;
}
.tile-row4-image .nft-fram-wrapper{
  bottom: 90%;
}
.right-nft-fram-wrapper .related-nft{
  margin-right: 10px;
}
.left-nft-fram-wrapper .related-nft{
  margin-left: 10px;
}
.right-nft-fram-wrapper .more-button{
  left: -6%;
}
.left-nft-fram-wrapper .more-button{
  right: -6%;
}
.rotate { animation: rotation 30s infinite linear; -webkit-animation: rotation 30s infinite linear; }
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.slow-up-down{
  animation: up-down 6s infinite linear;
}
.up-down{
  animation: up-down 3s infinite linear;
}
@keyframes up-down {
  0% { transform: translateY(0); }
  25% { transform: translateY(-10px); }
  50% { transform: translateY(-20px); }
  75% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}
.nft-modal .modal-dialog{max-width: 350px;margin: 0 auto 0 0}
.nft-modal.modal.fade .modal-dialog{transform: translate(-350px, 0);}
.nft-modal.modal.show .modal-dialog {
  transform: none;
}
.nft-modal .modal-content{height: 100vh;border-radius: 0;background: #272727;color: #fff;}
.nft-modal .nft-img{width: 60px;height: 60px;}
.nft-modal .nft-img img{border-radius: 50%;}
.nft-modal .nft-content{padding-left: 10px;width: calc(100% - 60px);}
.nft-content-wrapper:not(:last-child){margin-bottom: 15px;}
.nft-content-wrapper h2{margin-bottom: 0;font-size: 25px;line-height: 28px;}
.time-modal{position: fixed;z-index: 10;top: 50%;left: 50%;transform: translate(-50%, -50%); width: 500px;background: #000000;border-radius: 25px;border: 10px solid #fff;color: #fff;}
.time-modal .svg-inline--fa{width: 80px;height: 80px;cursor: pointer;}
.time-modal .arrow-wrapper{padding: 40px;}
.time-wrapper{font-size: 20px;line-height: 24px;padding: 10px 20px;border-radius: 5px;border: 2px solid #fff;}
/* Moving buttons */
.move-btns{position: fixed;top: 50%;left: 60px;transform: translateY(-50%);}
.move-btns .left-btn, .move-btns .right-btn{font-size: 20px;line-height: 24px;font-weight: 700; padding: 20px 10px;border-radius: 10px;background: #eee;text-transform: uppercase;width: 150px;}


/* New design changes */
.left-move .nft-fram{transform: translate(-160%, -50%);}
.right-move .nft-fram{transform: translate(240%, -50%);}
.drop-left-tile, .drop-right-tile{transform: translateY(100%);opacity: 0;}
.drop-left-nft .nft-fram{transform: translate(-160%, 200%);}
.drop-right-nft .nft-fram{transform: translate(240%, 200%);}
@media (max-width: 1699px) {
  /* .game-banner-wraper::after{bottom: 0;} */
  .related-nft .nft-name{
    font-size: 16px;
    line-height: 19px;
  }
  .game-banner-wraper .sun-image{
    top: -28%;
  }
  /* .tile-row{width: 68%;}
  .tile{max-width: 420px;}
  .stairs-image {top: 34%;transform: translate(-50%, -50%) rotateX(50deg);}
  .nft-fram {max-width: 180px;}
  .right-nft-fram-wrapper{
    right: 5%;
  }
  .left-nft-fram-wrapper{
    left: 5%;
  }
  .tile-row5-image .nft-fram-wrapper{
    bottom: 85%;
  }
  .tile-row3-image{top: 58%;}
  .tile-row4-image{top: 52%;}
  .tile-row5-image{top: 42%;} */
}
 